<template>
  <div class="prospecting">
      <div class="section is-medium has-text-centered has-text-justified">
        <div class="container is-max-desktop">
        <p class="title is-size-2 is-primary mb-6">Prospecting app</p>
        <p class="subtitle is-size-4">Coming soon!</p>
        <p class="subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Integer enim neque volutpat ac tincidunt vitae semper. Tristique senectus et netus et malesuada fames ac. Mi ipsum faucibus vitae aliquet nec ullamcorper. Amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et. A diam sollicitudin tempor id eu nisl. Massa id neque aliquam vestibulum morbi blandit cursus. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. Cursus metus aliquam eleifend mi in nulla posuere sollicitudin aliquam. Nec ullamcorper sit amet risus nullam eget felis. Eu mi bibendum neque egestas congue quisque egestas diam in. Volutpat diam ut venenatis tellus. Cursus turpis massa tincidunt dui ut ornare. Et ligula ullamcorper malesuada proin libero. Porttitor eget dolor morbi non arcu risus quis varius. Lectus sit amet est placerat in.</p>
        <p class="subtitle">
          Donec enim diam vulputate ut pharetra sit amet. Proin libero nunc consequat interdum varius sit amet. A iaculis at erat pellentesque adipiscing commodo elit. Vitae auctor eu augue ut lectus arcu bibendum. Magnis dis parturient montes nascetur ridiculus mus mauris. Eleifend donec pretium vulputate sapien. Commodo odio aenean sed adipiscing. Faucibus purus in massa tempor nec feugiat. Nec sagittis aliquam malesuada bibendum arcu vitae. Ac felis donec et odio. Ultrices tincidunt arcu non sodales neque sodales. Pellentesque dignissim enim sit amet venenatis urna cursus eget.
        </p>
        </div>
      </div>
  </div>
</template>

<script>


export default {
  name: 'Prospecting',

}
</script>