<template>
  <div class="home">
      <div class="section is-medium has-text-centered has-text-justified">
        <div class="container is-max-desktop">
        <p class="title is-size-2 is-primary mb-6">E-cco</p>
        <p class="subtitle"><b>Fait:</b> Le secteur de la construction en Suisse est l'un des secteurs avec les marges les plus basses. 
        <br> <b>Idée:</b> Ce n'est pas une fatalité pour ceux qui agissent et trouvent leurs nouveaux clients stratégiquement. </p>

        <button class="button is-primary">Demander un essai pour notre prospecteur automatisé.</button>
        <br>
        <br>

        <p class="subtitle"><b>Fait 1 :</b> Les carnets de travails des entreprises actives dans la rénovation sont fluctuants et incertains.
        <br> <b>Fait 2 : </b>Il est difficile de se faire une place sur le marché dans le canton de Genève qui fonctionne autour des réseaux de connaissance.
        <br> <b>Idée:</b> Il est possible de s'assurer une base de clients régulière en devenant systèmatiquement le premier à être mise au courant des intentions de rénovation des propriétaires. </p>
        <button class="button is-primary">Proposer un partenariat pour apparaitre sur notre calculateur de classe gratuit.</button>
        <br>
        <br>

        <p class="subtitle"><b>Fait:</b>Les demandes d'autorisation et de construction deviennent de plus en plus complexes, ce qui coûte du temps et de l'argent qui pourrait être alloué autrement.
        <br> <b>Idée: </b>Le processus de collecte peut-être automatisé, personnalisé, pour ne plus jamais avoir à passer une heure pour seulement préparer un dossier. </p>

        <button class="button is-primary">Demander un essai pour notre collecteur de données.</button>
        <br>
        <br>

          
        </div>
      </div>
  </div>
</template>

<script>


export default {
  name: 'Home',

}
</script>
